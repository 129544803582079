<template>
	<div v-if="pageShow">
		<my-dialog :title="title" :pageShow="pageShow" showCloseBtn @close="close_dialog">
			<div class="padding16">
				<el-form :inline="true" :model="param" ref="param" class="demo-form-inline">
					<el-form-item label="注册时间">
						<div class="time_box x_start_v">
							<el-date-picker size="mini" v-model="beginTime" type="datetime" placeholder="开始时间" @change="getPickerTime('beginTime')"></el-date-picker>
							<div class="line_box">-</div>
							<el-date-picker size="mini" v-model="endTime" type="datetime" placeholder="结束时间" @change="getPickerTime('endTime')"></el-date-picker>
						</div>
					</el-form-item>
					<el-form-item>
						<el-button size="mini" type="primary" :loading="loadingBtn" @click="onSubmit">查询</el-button>
					</el-form-item>
				</el-form>
				
				<div class="money_box x_start_v">
					<div>充值笔数：{{rechargeCount || 0}}</div>
					<div>充值金额：{{allRechargeMoney || 0}}</div>
				</div>
				
				<el-table :data="list" v-loading="loadingShow" size="mini" border style="width: 100%">
					<el-table-column prop="t_recharge_money" label="充值金额"></el-table-column>
					<el-table-column prop="t_create_time" label="时间"></el-table-column>
				</el-table>
				
				<el-pagination background layout="total, prev, pager, next, jumper" :page-size="param.pageSize" :current-page.sync="param.page"
				:total="total" @current-change="currentChange"></el-pagination>
				
			</div>
		</my-dialog>
	</div>
	
</template>

<script>
	export default{
		props:{
			userId: {
				type: Number
			},
			pageShow:{
				type:Boolean
			},
			title:{
				type:String
			}
		},
		data(){
			return{
				param: {
					t_user_id: null, //  用户Id
					beginTime: null, //  	开始时间
					endTime: null, //  	结束时间
					page: 1, //   1
					pageSize: 10, //   10
				},
				rechargeCount: null,
				allRechargeMoney: null,
				beginTime: null, //  	开始时间
				endTime: null, //  	结束时间
				loadingShow: false,
				loadingBtn:false,
				list: [],
				total: 0,
			}
		},
		watch: {
			//正确给 cData 赋值的 方法
			userId: function(newVal, oldVal) {
				console.log("oldVal",oldVal)
				this.param.t_user_id = newVal; //newVal即是chartData
				
				if(newVal){
					this.param.beginTime = null
					this.param.endTime = null
					this.param.page = 1
					this.beginTime = null
					this.endTime = null
					this.total = 0
					this.getList(); //newVal存在的话执行drawChar函数
				}
			
			}
		},
		methods:{
			getPickerTime(name) {
				console.log("name=", name, this.beginTime)
			
				if (this[name]) {
					let timeParam = this.getTime(this[name])
					let time = timeParam.year + '-' + timeParam.month + '-' + timeParam.day + ' ' + timeParam.h + ':' + timeParam.m +
						':' + timeParam.s
					this.param[name] = time
					console.log("this.param", this.param)
				} else {
					this.param[name] = null
				}
			
			
			},
			async onSubmit() {
				console.log('submit!');
				this.loadingBtn = true
				await this.getList()
				this.loadingBtn = false
			},
			async getList() {
				console.log("save")
				let that = this
				let param = {
					url: "myRechargeUserList",
					data: this.param
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					that.list = []
					if (res.status == 0) {
						that.list = res.data.rechargeUserList.list
						that.total = res.data.rechargeUserList.total
						
						that.rechargeCount = res.data.rechargeCount
						that.allRechargeMoney = res.data.allRechargeMoney
					}
				})
				this.loadingShow = false
			},
			currentChange(page) {
				this.param.page = page
				this.getList()
			},
			close_dialog(){
				console.log("222222")
				this.$emit("close")
			}
		}
	}
</script>

<style scoped="">
	.el-form-item{
		margin-bottom: 0 !important;
	}
	
</style>
